import React from 'react';
import ReactPlayer from 'react-player';

import {Card, Carousel, Row, Col} from 'react-bootstrap';

const Youtube = () => {
    const carousel = React.createRef();
    const videos = [
        {url: "https://www.youtube.com/embed/I8OOD-oJOB8", asset: 'stew.JPG'},
        {url: "https://www.youtube.com/embed/nCaM1Q-Fidc", asset: 'tv_face.jpeg'}
    ].map((e, i) => {
        return (
            <Carousel.Item className="Text" key={ e.asset }>
                <div className='player-wrapper'>
                    <ReactPlayer   
                        config={{ youtube: { playervars: { showinfo: 1 }}}}
                        url={e.url}
                        className='react-player'
                        light={'assets/' + e.asset}
                        width='75%'
                        height='75%'
                        controlsplaying
                        title='Music Video - Zokko'
                        />

                </div>
            </Carousel.Item>
        );
    });
    // console.log(photos);
    // return (
    //         <Carousel ref={carousel}>
    //             { photos }
    //         </Carousel>
    // );
    return(
        <Card className='Card'>
            <Card.Header className='Header'>recent Music Videos</Card.Header>
            <Card.Body>
                {/* <div className='player-wrapper'> */}
                    <Carousel ref={carousel} interval={null}>

                        {/* <ReactPlayer 
                        config={{ youtube: { playerVars: { showinfo: 1 } } }}
                        url="https://www.youtube.com/embed/nCaM1Q-Fidc" 
                        className='react-player'
                        width='100%'
                        height='100%'
                        controls
                        light='/assets/tv_face.jpeg'
                        playing
                        title="Headed to the Party"
                        />  */}

                        {videos}

                    </Carousel>
                {/* </div> */}
            </Card.Body>
        </Card>
    );
}

const Spotify = () => {
    return(
        <Card className='Card'>
            <Card.Header className='Header'>stream on Spotify</Card.Header>
            <Card.Body>
                <iframe 
                    id='spotify' 
                    src="https://open.spotify.com/embed/artist/756bsc3EfUreDxUc7ki6Pd" 
                    frameBorder="0" 
                    allowtransparency="true" 
                    allow="encrypted-media" 
                    title="Headed to the Party"
                    // SameSite='none' causes problems
                    secure='true'
                ></iframe>
            </Card.Body>
        </Card>
    )
}

const Biography = () => {
    const album = React.createRef();
    return(
        <Card className='Card'>
            <Card.Header className='Header'>about Zokko</Card.Header>
            <Card.Body>
                <Row>
                    <Col sm='4'>
                        <Card.Text className="Text">Like a Nebula, Zokko is a bright light shining through luminous matter to bring you a music of higher consciousness.</Card.Text>
                        <Card.Text className="Text">I've met Zokko he's an alien&nbsp;<span role='img' aria-label='alien'>👽</span></Card.Text>
                    </Col>
                    <Col sm='8'>
                        { photoAlbum(album) }
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

const photoAlbum = (album) => {
    const photos = [
        'agh.JPG',
        // 'bush.jpg',
        // 'collage.jpg',
        'guitarbarf.jpeg',
        'sunset.JPG',
        // 'tree.jpg',
        // 'treehug.jpeg',
        'twilight.jpeg',
        'zen.JPG'
    ].map((e, i) => {
        return (
            <Carousel.Item className="Text" key={ e.substr(0, e.indexOf('.')) }>
                <img
                className="d-block w-100"
                src={ '/assets/' + e }
                alt={ 'Slide ' + (i+1) }
                />
            </Carousel.Item>
        );
    });
    // console.log(photos);
    return (
            <Carousel ref={album}>
                { photos }
            </Carousel>
    );
}

export { Youtube, Spotify, Biography  };